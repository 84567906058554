<template>
  <div class="materials-info">
    <div class="item" v-for="(item, i) in materialList" :key="i">
      <van-cell
        is-link
        clickable
        :required="item.isNeed == '1'"
        :title="item.materialName"
        :value="`已上传${item.materialImages?.length || 0}张`"
        @click="choiceItem(item)"
      >
        <template #label>
          <div class="m-item-t">
            <span
              v-if="item.exampleUrl"
              class="custom-title clr-b"
              @click="previewDemo(item.exampleUrl)"
            >
              <van-icon name="eye-o" />
              示例
            </span>
            <span class="custom-title"
              >({{ acceptTipsFn(item.attachType) }})</span
            >
          </div>
        </template>
      </van-cell>
    </div>
    <MaterialsInfoUpload
      v-model="showDetailPicker"
      :itemdata="selectItem"
      @update="getMaterialsList"
    />
    <!-- 
      @update="setMaterialList" -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { materialsConfigList } from "@/api/apply";
export default {
  components: {
    MaterialsInfoUpload: () => import("./MaterialsInfoUpload")
  },
  data() {
    return {
      materialList: [],
      bizNo: "",
      selectItem: {},
      showDetailPicker: false,
      prePlatOrderNo: ""
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapGetters(["getUserOrderInfo"]),
    init() {
      const data = this.getUserOrderInfo();
      this.bizNo = data.bizNo || "";
      this.prePlatOrderNo = data.prePlatOrderNo || "";
      this.getMaterialsList();
    },
    acceptTipsFn(fileType) {
      if (fileType == "PIC") {
        return "请上传.jpg, .jpeg, .png格式文件";
      } else if (fileType == "VIDEO") {
        return "请上传视频格式文件";
      } else if (fileType == "DOC") {
        return "请上传pdf格式文件";
      } else {
        return "image/*,video/*";
      }
    },
    async getMaterialsList() {
      try {
        const { data } = await materialsConfigList(this.bizNo);
        this.materialList = (data || []).map((e) => {
          e.lists = e.materialImages?.map((ee) => {
            return {
              ...ee,
              fileUrl: ee.imageUrl,
              fileName: ee.imageName
            };
          });
          return e;
        });
        console.log(this.materialList);
      } catch (error) {
        this.$errMsg(error);
      }
    },
    choiceItem(item) {
      this.selectItem = item;
      this.showDetailPicker = true;
    }
    // async setMaterialList() {
    //   const item = this.materialList.find(e => e.materialCode == this.materialCode)
    //   const images = item?.materialImages
    //   const materialList = [...this.materialConfig].map((item) => ({
    //     ...item,
    //     bizNo: this.bizNo,
    //     lists: (images || []).map((item2) => ({
    //       ...item2,
    //       fileUrl: item2.imageUrl,
    //       fileName: item2.imageName
    //     }))
    //   }));
    //   this.materialList = [...materialList];
    // },
  }
};
</script>
<style lang="scss" scoped>
.materials-info {
}
</style>
